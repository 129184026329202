import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { firestorePlugin } from 'vuefire';
import VueMq from 'vue-mq';
import { auth } from '@/firebase';

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 900,
    laptop: 1264,
    desktop: Infinity,
  },
});

Vue.use(firestorePlugin);

Vue.config.productionTip = false;

export const eventBus = new Vue();

var debounceTimeout;
const DebounceDueTime = 200;

auth.onAuthStateChanged(() => {
  if (debounceTimeout) clearTimeout(debounceTimeout);

  debounceTimeout = setTimeout(() => {
    debounceTimeout = null;

    new Vue({
      router,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }, DebounceDueTime);
});
