<template>
  <div class="my-8">
    <div class="d-flex align-center my-8 text-uppercase">
      <v-divider
        style="border-width: thin 0 2px 0; max-width: 50px; border-color: black"
        class="mr-4"
        horizontal
      ></v-divider>
      <h1>What We <span class="primary--text font-weight-bold">Do</span></h1>
    </div>

    <v-row>
      <v-col cols="12" md="6">
        <v-card tile flat class="transparent fill-height">
          <v-img max-height="324" cover src="@/assets/images/IMG_2949.jpg">
            <div
              class="fill-height d-flex flex-column justify-end px-4 py-4 white--text"
              style="
                width: 100%;
                background-color: rgba(0, 0, 0, 0.45);
                height: 100%;
              "
            >
              <div class="d-flex justify-space-between align-end">
                <div>
                  <h4>Remodeling & Renovations</h4>
                  <div>Kitchen, Bath, etc...</div>
                </div>

                <div>
                  <v-btn to="/remodeling-renovations" small text dark>
                    gallery
                    <v-icon right small>mdi-arrow-right</v-icon></v-btn
                  >
                </div>
              </div>
            </div>
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" md="6">
            <v-card tile flat class="transparent">
              <v-img max-height="150" cover src="@/assets/images/IMG_2991.jpg">
                <div
                  class="fill-height d-flex flex-column justify-end px-4 py-4 white--text"
                  style="
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0.45);
                    height: 100%;
                  "
                >
                  <div class="d-flex justify-space-between align-end">
                    <div>
                      <h4>Painting & Decor</h4>
                    </div>

                    <div>
                      <v-btn to="/painting-decor" small text dark icon>
                        <v-icon small>mdi-arrow-right</v-icon></v-btn
                      >
                    </div>
                  </div>
                </div>
              </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card tile flat class="transparent">
              <v-img max-height="150" cover src="@/assets/images/IMG_2951.jpg">
                <div
                  class="fill-height d-flex flex-column justify-end px-4 py-4 white--text"
                  style="
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0.45);
                    height: 100%;
                  "
                >
                  <div class="d-flex justify-space-between align-end">
                    <div>
                      <h4>Roofing</h4>
                    </div>

                    <div>
                      <v-btn to="/roofing" small text dark icon>
                        <v-icon small>mdi-arrow-right</v-icon></v-btn
                      >
                    </div>
                  </div>
                </div>
              </v-img>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-card tile flat class="transparent">
              <v-img max-height="150" cover src="@/assets/images/fence.jpg">
                <div
                  class="fill-height d-flex flex-column justify-end px-4 py-4 white--text"
                  style="
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0.45);
                    height: 100%;
                  "
                >
                  <div class="d-flex justify-space-between align-end">
                    <div>
                      <h4>Fencing & Decking</h4>
                    </div>

                    <div>
                      <v-btn to="/fencing-decking" small text dark icon>
                        <v-icon small>mdi-arrow-right</v-icon></v-btn
                      >
                    </div>
                  </div>
                </div>
              </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card tile flat class="transparent">
              <v-img max-height="150" cover src="@/assets/images/IMG_2989.jpg">
                <div
                  class="fill-height d-flex flex-column justify-end px-4 py-4 white--text"
                  style="
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0.45);
                    height: 100%;
                  "
                >
                  <div class="d-flex justify-space-between align-end">
                    <div>
                      <h4>Additions</h4>
                    </div>

                    <div>
                      <v-btn to="/additions" small text dark icon>
                        <v-icon small>mdi-arrow-right</v-icon></v-btn
                      >
                    </div>
                  </div>
                </div>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'WhatWeDo',
};
</script>
