<template>
  <div class="mb-16">
    <div class="d-flex align-center my-8 text-uppercase">
      <v-divider
        style="border-width: thin 0 2px 0; max-width: 50px; border-color: black"
        class="mr-4"
        horizontal
      ></v-divider>
      <h1>What We <span class="primary--text font-weight-bold">Offer</span></h1>
    </div>
    <v-row>
      <v-col cols="6" md="2">
        <v-card
          outlined
          to="/kitchens"
          flat
          tile
          class="d-flex flex-column justify-center align-center pointer"
        >
          <v-card-text class="d-flex flex-column justify-center">
            <v-icon x-large color="offblack">mdi-countertop</v-icon>
          </v-card-text>
          <v-card-subtitle class="pt-0 text-center">Kitchens</v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="6" md="2">
        <v-card
          outlined
          to="/baths"
          flat
          tile
          class="d-flex flex-column justify-center align-center pointer"
        >
          <v-card-text class="d-flex flex-column justify-center">
            <v-icon x-large color="offblack">mdi-bathtub</v-icon>
          </v-card-text>
          <v-card-subtitle class="pt-0 text-center">Baths</v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="6" md="2">
        <v-card
          outlined
          to="/painting-decor"
          flat
          tile
          class="d-flex flex-column justify-center align-center pointer"
        >
          <v-card-text class="d-flex flex-column justify-center">
            <v-icon x-large color="offblack">mdi-brush-variant</v-icon>
          </v-card-text>
          <v-card-subtitle class="pt-0 text-center"
            >Painting & Decor</v-card-subtitle
          >
        </v-card>
      </v-col>
      <v-col cols="6" md="2">
        <v-card
          outlined
          to="/fencing-decking"
          flat
          tile
          class="d-flex flex-column justify-center align-center pointer"
        >
          <v-card-text class="d-flex flex-column justify-center">
            <v-icon x-large color="offblack">mdi-fence</v-icon>
          </v-card-text>
          <v-card-subtitle class="pt-0 text-center"
            >Fencing & Decking</v-card-subtitle
          >
        </v-card>
      </v-col>
      <v-col cols="6" md="2">
        <v-card
          outlined
          to="/custom-builds"
          flat
          tile
          class="d-flex flex-column justify-center align-center pointer"
        >
          <v-card-text class="d-flex flex-column justify-center">
            <v-icon x-large color="offblack">mdi-bulldozer</v-icon>
          </v-card-text>
          <v-card-subtitle class="pt-0 text-center"
            >Custom Build</v-card-subtitle
          >
        </v-card>
      </v-col>
      <v-col cols="6" md="2">
        <v-card
          outlined
          to="/roofing"
          flat
          tile
          class="d-flex flex-column justify-center align-center pointer"
        >
          <v-card-text class="d-flex flex-column justify-center">
            <v-icon x-large color="offblack">mdi-home-roof</v-icon>
          </v-card-text>
          <v-card-subtitle class="pt-0 text-center">Roofing</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="text-center my-4">
          Don't see what you are looking for? Contact us today to find out more
          about our services.
        </div>
        <div class="d-flex justify-center">
          <v-btn color="primary" to="/contact" class="mr-4">Contact Us</v-btn>
          <v-btn color="primary" to="/our-work" outlined>Gallery</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'WhatWeOffer',
};
</script>
