goldentouchconstructiongroup.com
<template>
  <v-app>
    <component :user="user" :is="layout">
      <router-view :user="user" :layout.sync="layout" />
    </component>
  </v-app>
</template>

<script>
import * as fb from '@/firebase';
import '@/app.css';

export default {
  name: 'App',
  components: {},
  data: () => ({
    layout: `div`,
    user: null,
    authUser: null,
    isAdmin: false,
    claims: {},
  }),
  watch: {
    authUser: {
      immediate: true,
      async handler() {
        if (this.authUser) {
          // await fb.auth.currentUser.getIdTokenResult().then((claims) => {
          //   let userClaims = claims.claims;

          //   if (userClaims.admin) {
          //     this.isAdmin = userClaims.admin;
          //   } else {
          //     this.isAdmin = false;
          //   }
          // });

          this.user = {
            isAdmin: this.isAdmin,
            id: fb.auth.currentUser.uid,
            ...this.authUser,
          };
        }
      },
    },
  },
  async created() {
    if (fb.auth.currentUser) {
      await this.$bind(
        'authUser',
        fb.db.collection('users').doc(fb.auth.currentUser.uid)
      );
    }
  },
};
</script>
