import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#FC0100',
        secondary: '#3D0000',
        tertiary: '#00B032',
        heading: '#000',
        body: '#000',
        tan: '#F2F1EB',
        background: '#FAF9F6',
        accent: '#79d0e8',
        orange: '#F26430',
        error: '#BD0000',
        warning: '#F26430',
        info: '#79d0e8',
        red: '#7D0000',
        pink: '#D91A82',
        success: '#00FC47',
        green: '#00FC47',
        darkgreen: '#00B032',
        menu: '#000',
        white: '#ffffff',
        offwhite: '#E5E5E5',
        offblack: '#20202E',
        black: '#000000',
        lightbg: '#FDFDFD',
        darkwhite: '#F1F2F3',
        bordercolor: '#F3F7F3',
      },
    },
  },
});
