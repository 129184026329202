<template>
  <div class="main-nav navbar-container">
    <v-card elevation="0">
      <v-app-bar
        color="white"
        height="auto"
        class="pl-0 pr-0"
        flat
        aboslute
        prominent
        nav
      >
        <v-container
          :class="
            $mq == 'mobile' ? 'd-flex align-center ' : 'd-flex align-center '
          "
          style="background: var(--v-white-base)"
        >
          <v-toolbar-title
            :class="
              $mq == 'mobile'
                ? 'd-flex align-center pa-2 text-h5'
                : 'd-flex align-center pa-2 text-h5'
            "
            style="cursor: pointer"
            color="primary"
          >
            <v-img
              @click="$route.path != '/' ? $router.push('/') : null"
              class="mr-4"
              :max-width="$mq == 'mobile' ? '100px' : '150px'"
              src="@/assets/logo.svg"
              contain
            ></v-img>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <div
            v-if="$mq === 'laptop' || $mq === 'desktop'"
            class="d-flex justify-center"
          >
            <!-- <v-btn
              color="menu"
              class="text-capitalize"
              @click="navigateTo('services')"
              text
              plain
            >
              Our Services
            </v-btn> -->

            <v-btn
              color="menu"
              class="text-capitalize"
              @click="navigateTo('about')"
              text
              plain
            >
              About Us
            </v-btn>

            <v-btn
              color="menu"
              to="/our-work"
              class="text-capitalize"
              text
              plain
            >
              Our Work
            </v-btn>

            <v-btn
              color="menu"
              class="text-capitalize"
              to="/contact"
              text
              plain
            >
              Contact
            </v-btn>

            <v-btn color="primary" to="/contact" dark depressed
              >Get A Qoute</v-btn
            >
          </div>
          <slot name="drawer"></slot>
        </v-container>
      </v-app-bar>
    </v-card>
  </div>
</template>

<script>
import { auth, db } from '@/firebase';
import { eventBus } from '@/main.js';
export default {
  name: 'NavbarComponent',
  props: ['user'],
  data() {
    return {
      right: null,
      show: false,
      isUser: false,
      accountLink: '/',
    };
  },
  watch: {
    user: {
      immediate: true,
      async handler() {
        if (this.user) {
          const qrCodes = await db
            .collection('users')
            .doc(this.user.id)
            .collection('qr_codes')
            .limit(1)
            .get();

          this.isUser = true;
          if (this.user.isAdmin) {
            this.accountLink = '/admin/dashboard';
          } else {
            if (qrCodes.docs.length > 0) {
              this.accountLink = '/qr-codes';
            } else {
              this.accountLink = '/settings';
            }
          }
        } else {
          this.isUser = false;
          this.accountLink = '/sign-up';
        }
      },
    },
  },
  methods: {
    navigateTo(val) {
      if (this.$route.path != '/') {
        this.$router.push(`/#${val}`);
      } else {
        eventBus.$emit('navigate', `#${val}`);
      }
    },
    goHome() {
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
    },
    signOut() {
      auth.signOut();
      this.$router.go();
    },
  },
};
</script>
