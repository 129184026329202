<template>
  <div id="top">
    <BannersComponent />
    <v-container>
      <WhatWeDo id="services" />
      <ReviewsView />
    </v-container>
    <WhyUs />
    <v-container>
      <WhatWeOffer />
    </v-container>
  </div>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout';
import { eventBus } from '@/main.js';
import BannersComponent from '@/components/homepage/Banners';
import WhatWeDo from '@/components/homepage/WhatWeDo';
import ReviewsView from '@/components/homepage/ReviewsView';
import WhatWeOffer from '@/components/homepage/WhatWeOffer';
import WhyUs from '@/components/homepage/WhyUs';

export default {
  name: 'HomeView',
  components: {
    BannersComponent,
    WhatWeDo,
    WhatWeOffer,
    WhyUs,
    ReviewsView,
  },

  created() {
    eventBus.$on('navigate', (val) => {
      if (val == '#top') {
        window.scrollTo(0, 0, { behavior: 'smooth' });
      } else {
        const el = document.querySelector(val);
        el.scrollIntoView({ behavior: 'smooth' });
      }
    });
    this.$emit(`update:layout`, PublicLayout);
  },
};
</script>
