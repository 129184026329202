<template>
  <div>
    <div class="white">
      <v-container class="py-4 my-0">
        <v-row>
          <v-col cols="6">
            <div class="text-caption d-flex align-center">
              <v-icon class="mr-2" color="primary">mdi-cellphone</v-icon>
              <a
                class="black--text text-subtitle text-md-subtitle"
                :href="`tel:${business.phone}`"
                >{{ business.phone }}</a
              >
            </div>
          </v-col>
          <v-col cols="6">
            <div class="text-caption d-flex align-center justify-end">
              <div class="mr-2">Social:</div>
              <v-icon
                color="primary"
                @click="openSocial(`${business.facebook}`)"
                class="mr-2"
                >mdi-facebook</v-icon
              >
              <v-icon
                color="primary"
                @click="openSocial(`${business.instagram}`)"
                class="mr-2"
                >mdi-instagram</v-icon
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
    </div>
    <v-navigation-drawer
      v-model="drawer"
      color="darkbg"
      width="250"
      :mini-variant="miniVariant"
      :expand-on-hover="expandOnHover"
      temporary
      fixed
      app
    >
      <v-list dense nav>
        <v-list>
          <v-list-item inactive>
            <v-list-item-content>
              <v-list-item-title
                class="d-flex align-center pa-2"
                style="cursor: pointer"
                color="primary"
              >
                <!-- <v-img class="mr-4" src="@/assets/logo.svg" contain></v-img> -->
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list-item-group color="primary">
          <v-list-item @click="navigateTo('top')" inactive>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="navigateTo('about')" inactive>
            <v-list-item-content>
              <v-list-item-title>About Us</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/our-work">
            <v-list-item-content>
              <v-list-item-title>Our Work</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/contact">
            <v-list-item-content>
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item to="/contact">
            <v-list-item-content>
              <v-list-item-title>Get a Quote</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <NavbarComponent :user="currentUser">
      <template
        v-if="$mq === 'mobile' || $mq === 'tablet' || !drawer"
        v-slot:drawer
      >
        <v-app-bar-nav-icon
          v-if="$mq === 'mobile' || $mq === 'tablet'"
          color="black"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </template>
    </NavbarComponent>

    <v-main class="public-layout" :mb="$mq == 'mobile' ? 'mb-16' : ''">
      <slot :user="currentUser"></slot>
    </v-main>
    <PublicFooter />
    <v-btn
      @click="scrollUp()"
      color="primary"
      small
      fixed
      right
      bottom
      elevation="2"
      fab
      ><v-icon>mdi-arrow-up-drop-circle-outline</v-icon></v-btn
    >
  </div>
</template>

<script>
import { auth } from '@/firebase.js';
import { eventBus } from '@/main.js';
import NavbarComponent from '@/components/public/NavbarComponent';
import PublicFooter from '@/components/public/PublicFooter';
import * as business from '@/businessDetails.js';
export default {
  name: 'PublicLayout',
  props: ['user'],
  components: {
    NavbarComponent,
    PublicFooter,
  },
  data() {
    return {
      business: business,
      fixed: false,
      drawer: false,
      miniVariant: false,
      expandOnHover: false,
      isUser: false,
      accountLink: '/',
    };
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        this.currentUser = this.user;
      },
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    signOut() {
      auth.signOut();
      this.$router.go();
    },
    navigateTo(val) {
      if (this.$route.path != '/') {
        this.drawer = false;
        this.$router.push(`/#${val}`);
      } else {
        this.drawer = false;
        eventBus.$emit('navigate', `#${val}`);
      }
    },
    scrollUp() {
      window.scrollTo(0, 0, { behavior: 'smooth' });
    },
    openSocial(val) {
      window.open(val, '_blank');
    },
  },
};
</script>
