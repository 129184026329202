<template>
  <div
    v-if="content"
    style="background-color: var(--v-secondary-base)"
    class="my-8"
  >
    <v-container>
      <v-row class="d-flex justify-center my-8">
        <v-col cols="12" md="6" class="d-flex flex-column justify-center">
          <!-- <h3 class="white--text d-flex align-center">
            <v-icon left color="white">mdi-check-circle-outline</v-icon>
            Satisfaction Guarenteed
          </h3>
          <h3 class="white--text d-flex align-center">
            <v-icon left color="white">mdi-check-circle-outline</v-icon>
            Competitive Pricing
          </h3>
          <h3 class="white--text d-flex align-center">
            <v-icon left color="white">mdi-check-circle-outline</v-icon>
            Free quotes and consultations
          </h3> -->
          <div class="fill-height d-flex justify-center align-center">
            <v-img
              class="white mr-4"
              max-width="50%"
              src="@/assets/logo.svg"
              contain
            ></v-img>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="d-flex flex-column justify-center">
          <h2 class="my-8 white--text" id="about" v-if="content.title">
            {{ content.title }}
          </h2>
          <div class="white--text" v-if="content.desc">
            {{ content.desc }}
          </div>

          <div class="d-flex justify-center my-8">
            <v-btn color="white" to="/our-work" outlined>View Our Work</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { eventBus } from '@/main.js';
import * as fb from '@/firebase';
export default {
  name: 'WhyUs',
  data: () => ({
    content: '',
  }),
  async created() {
    await this.$bind('content', fb.db.collection('pages').doc('about'));
    eventBus.$on('navigate', (val) => {
      if (val == '#top') {
        window.scrollTo(0, 0, { behavior: 'smooth' });
      } else {
        const el = document.querySelector(val);
        el.scrollIntoView({ behavior: 'smooth' });
      }
    });
  },
};
</script>
