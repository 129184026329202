import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { auth } from '@/firebase';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: async (to, from, next) => {
      document.title = `Homepage | ${process.env.VUE_APP_TITLE}`;
      const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);

      next();
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/ContactUs.vue'),
    beforeEnter: async (to, from, next) => {
      document.title = `Contact Us | ${process.env.VUE_APP_TITLE}`;
      const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);

      next();
    },
  },
  {
    path: '/our-work',
    name: 'ourWork',
    component: () => import('@/views/OurWork.vue'),
    beforeEnter: async (to, from, next) => {
      document.title = `Our Work | ${process.env.VUE_APP_TITLE}`;
      const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);

      next();
    },
  },
  {
    path: '/remodeling-renovations',
    name: 'remodeling',
    component: () => import('@/views/RenovationsView.vue'),
    beforeEnter: async (to, from, next) => {
      document.title = `Remodeling & Renovations | ${process.env.VUE_APP_TITLE}`;
      const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);

      next();
    },
  },
  {
    path: '/additions',
    name: 'additions',
    component: () => import('@/views/AdditionsView.vue'),
    beforeEnter: async (to, from, next) => {
      document.title = `Home Additions | ${process.env.VUE_APP_TITLE}`;
      const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);

      next();
    },
  },
  {
    path: '/baths',
    name: 'baths',
    component: () => import('@/views/BathsView.vue'),
    beforeEnter: async (to, from, next) => {
      document.title = `Bath Remodeling | ${process.env.VUE_APP_TITLE}`;
      const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);

      next();
    },
  },
  {
    path: '/custom-builds',
    name: 'customBuilds',
    component: () => import('@/views/CustomBuildsView.vue'),
    beforeEnter: async (to, from, next) => {
      document.title = `Custom Builds | ${process.env.VUE_APP_TITLE}`;
      const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);

      next();
    },
  },
  {
    path: '/fencing-decking',
    name: 'fencingDecking',
    component: () => import('@/views/FencingView.vue'),
    beforeEnter: async (to, from, next) => {
      document.title = `Fencing & Decking | ${process.env.VUE_APP_TITLE}`;
      const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);

      next();
    },
  },
  {
    path: '/kitchens',
    name: 'kitchens',
    component: () => import('@/views/KitchensView.vue'),
    beforeEnter: async (to, from, next) => {
      document.title = `Kitchen Remodeling | ${process.env.VUE_APP_TITLE}`;
      const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);

      next();
    },
  },
  {
    path: '/painting-decor',
    name: 'painting',
    component: () => import('@/views/PaintingView.vue'),
    beforeEnter: async (to, from, next) => {
      document.title = `Painting & Decor | ${process.env.VUE_APP_TITLE}`;
      const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);

      next();
    },
  },
  {
    path: '/roofing',
    name: 'roofing',
    component: () => import('@/views/RoofingView.vue'),
    beforeEnter: async (to, from, next) => {
      document.title = `Roofing | ${process.env.VUE_APP_TITLE}`;
      const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);

      next();
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/PrivacyPolicy.vue'),
    beforeEnter: async (to, from, next) => {
      document.title = `Privacy Policy | ${process.env.VUE_APP_TITLE}`;
      const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', _desc);

      next();
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/admin/LoginView.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        document.title = `Login | ${process.env.VUE_APP_TITLE}`;
        const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', _desc);
        next();
      } else {
        next('/admin');
      }
    },
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: () => import('@/views/admin/AdminView.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login');
      } else {
        document.title = `Admin | ${process.env.VUE_APP_TITLE}`;
        const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', _desc);
        next();
      }
    },
  },
  {
    path: '/admin/reviews',
    name: 'AdminReviews',
    component: () => import('@/views/admin/AdminReviewsView.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login');
      } else {
        document.title = `Admin | ${process.env.VUE_APP_TITLE}`;
        const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', _desc);
        next();
      }
    },
  },
  {
    path: '/admin/pages',
    name: 'PagesView',
    component: () => import('@/views/admin/PagesView.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login');
      } else {
        document.title = `Admin | ${process.env.VUE_APP_TITLE}`;
        const _desc = `Fanous Brothers Construction provides a variety of services to the construction industry including: Home Renovations, Interior Design, and Construction in the Greater Cleveland Area.`;
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', _desc);
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
