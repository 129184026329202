<template>
  <v-footer
    color="white"
    class="d-flex justify-center align-center"
    absolute
    app
    padless
  >
    <div class="top-footer">
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <v-card
              color="transparent"
              class="d-flex justify-space-between align-center"
              flat
              tile
            >
              <v-card-text
                class="d-flex flex-column justify-center align-center"
              >
                <div class="black--text text-subtitle text-md-subtitle ml-2">
                  Hours: Mon-Sat: 8am - 7pm
                </div>
                <div class="secondary--text">
                  &copy; Fanous Brothers Construction
                  {{ new Date().getFullYear() }}
                </div>
                <v-btn
                  to="/privacy-policy"
                  class="text-capitalize"
                  small
                  text
                  color="primary"
                  >Privacy Policy</v-btn
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- <div class="footer-hr">
      <v-container>
        <v-row>
         
          <v-col cols="12" md="6">
            <v-card
              color="transparent"
              class="d-flex justify-space-between"
              flat
              tile
              v-if="content"
            >
              <v-card-text
                class="secondary--text d-flex align-center justify-end"
              >
                <div class="mr-2">Follow Us:</div>
                <v-btn
                  v-if="content.instagram_link"
                  class="mr-2"
                  color="secondary"
                  icon
                  @click="openLink(content.instagram_link)"
                  ><v-icon>mdi-instagram</v-icon></v-btn
                >
                <v-btn
                  v-if="content.facebook_link"
                  class="mr-2"
                  color="secondary"
                  icon
                  @click="openLink(content.facebook_link)"
                  ><v-icon>mdi-facebook</v-icon></v-btn
                >
                <v-btn
                  v-if="content.twitter_link"
                  color="secondary"
                  icon
                  @click="openLink(content.twitter_link)"
                  ><v-icon>mdi-twitter</v-icon></v-btn
                >
                <v-btn
                  v-if="content.linkedin_link"
                  color="secondary"
                  icon
                  @click="openLink(content.linkedin_link)"
                  ><v-icon>mdi-linkedin</v-icon></v-btn
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div> -->
  </v-footer>
</template>

<script>
export default {
  name: 'PublicFooter',
  data: () => ({
    content: null,
  }),

  methods: {
    openLink(link) {
      window.open(link, '_blank');
    },
  },
};
</script>

<style></style>
