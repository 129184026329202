<template>
  <div v-if="content">
    <v-sheet height="400">
      <v-row class="fill-height max-width" align="center" justify="center">
        <v-img
          max-height="100%"
          width="100%"
          cover
          src="@/assets/images/IMG_2984.jpg"
        >
          <div
            class="fill-height d-flex justify-center align-center flex-column py-8 white--text"
            style="
              width: 100%;
              background-color: rgba(0, 0, 0, 0.45);
              height: 100%;
            "
          >
            <div
              :style="
                $mq == 'mobile' || $mq == 'tablet'
                  ? 'max-width: 90%'
                  : 'max-width: 90%'
              "
              class="text-center"
            >
              <h1
                class="text-h4 text-md-h3 text-uppercase font-weight-bold mb-4"
              >
                {{ content.title }}
              </h1>
              <div v-if="content.desc">{{ content.desc }}</div>
              <v-btn color="primary" to="/contact" elevation="0" class="mt-4"
                >Contact Us</v-btn
              >
            </div>
          </div>
        </v-img>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { eventBus } from '@/main.js';
import * as fb from '@/firebase';
export default {
  name: 'BannersComponent',
  data: () => ({
    content: '',
  }),
  async created() {
    await this.$bind('content', fb.db.collection('pages').doc('homepage'));
  },
  methods: {
    navigateTo(val) {
      if (this.$route.path != '/') {
        this.$router.push(`/#${val}`);
      } else {
        eventBus.$emit('navigate', `#${val}`);
      }
    },
  },
};
</script>
