import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: 'AIzaSyDcchQDaXcTfQnp8dJ28a8Ag6onUVfQYs0',
  authDomain: 'fanous-construction.firebaseapp.com',
  projectId: 'fanous-construction',
  storageBucket: 'fanous-construction.appspot.com',
  messagingSenderId: '70668835428',
  appId: '1:70668835428:web:c3db3b3256270682319d71',
  measurementId: 'G-9PZ90PL8KC',
};

// Initialize Firebase
const dev = false;

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const analytics = firebase.analytics();
const functions = firebase.functions();
if (dev) functions.useEmulator('localhost', 5001);

export { db, auth, storage, analytics, functions };
