<template>
  <div v-if="reviews.length > 0" class="my-8">
    <div class="d-flex align-center my-8 text-uppercase">
      <v-divider
        style="border-width: thin 0 2px 0; max-width: 50px; border-color: black"
        class="mr-4"
        horizontal
      ></v-divider>
      <h1>
        Customer <span class="primary--text font-weight-bold">Reviews</span>
      </h1>
    </div>

    <v-row>
      <v-col cols="12">
        <v-carousel
          cycle
          height="400"
          interval="10000"
          hide-delimiter-background
          show-arrows-on-hover
          light
        >
          <v-carousel-item v-for="review in reviews" :key="review.id">
            <v-sheet color="transparent" height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <v-card flat class="mx-auto transparent" max-width="600">
                  <v-card-title
                    class="text-center d-flex align-center justify-center"
                  >
                    {{ review.title }}
                  </v-card-title>

                  <v-rating
                    class="d-flex justify-center mb-2"
                    empty-icon="mdi-star-outline"
                    full-icon="mdi-star"
                    half-icon="mdi-star-half-full"
                    disabled
                    length="5"
                    size="32"
                    readonly
                    background-color="primary"
                    color="primary"
                    v-model="review.rating"
                  ></v-rating>
                  <v-card-text class="text-center"
                    >By: {{ review.author }}</v-card-text
                  >
                  <v-card-text class="text-center">{{
                    review.desc
                  }}</v-card-text>
                </v-card>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as fb from '@/firebase.js';
export default {
  name: 'ReviewsView',
  data: () => ({
    reviews: [],
  }),
  async created() {
    await this.$bind('reviews', fb.db.collection('reviews'));
  },
};
</script>
